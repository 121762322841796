import { render, staticRenderFns } from "./MainIndex.vue?vue&type=template&id=0fb9f763&scoped=true&"
import script from "./MainIndex.vue?vue&type=script&lang=js&"
export * from "./MainIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb9f763",
  null
  
)

export default component.exports